import {React} from "react";
import Partners from "./Partners";

export default function Main(){
    return (
        <>
          <section className="body-font text-base text-black-500 dark:text-white flex justify-center items-center">
            <div className="container px-5 py-4 mx-auto">  
    
            <div className="px-2 py-2">
            <Partners />
            </div><br /> 
            </div>
          </section> 
    
      </>
      );
}