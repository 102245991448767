import {React} from "react";
import aliexpress1 from "../assets/images/aliexpress1.jpg";
import aliexpress2 from "../assets/images/aliexpress2.jpg";



function PartnerCard({_url, _image, _name,_description}){
    return (
        <>
            <div className="xl:w-full md:w-full p-2">
                <div className="card rounded-lg">
                <a href={_url} target="_blank"><img className="h-40 rounded w-full mb-6" src={_image} alt={_name}/></a>  
                <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font"></h3>
                <a href={_url} target="_blank" className="text-lg text-orange-600 font-medium title-font  px-2">{_name}</a>
                <a href={_url} target="_blank"  className="leading-relaxed text-gray-500 px-2 mb-4">{_description}</a>
                </div>
            </div>
        </>
    )
}


export default function Partners() {
    let main_url = "https://s.click.aliexpress.com/e/_DmIVaS9";
    let second_url = "https://s.click.aliexpress.com/e/_DmIVaS9";

    let partners = [
        {id:1, image:aliexpress2, url : "https://s.click.aliexpress.com/e/_DmIVaS9", name:"AliExpress", description:"Smarter Shopping. Better Living."},
    ];

    return (
        <>
        <div className="w-full my-1 flex items-center sm:mx-auto sm:w-full before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
            <p className="mx-1 mb-0 text-center  dark:text-white">Expected
                <a href={main_url} target="_blank"  className="text-sky-500 font-bold"> an online e-commerce platform</a>  with 
                <a href={second_url} target="_blank"   className="text-purple-500 font-bold"> cheap products</a>?
            </p>
        </div>        
        <section className="text-gray-600 body-font">
        <div className="container px-5 py-4 mx-auto">
            <div className="flex flex-wrap w-full mb-20">
            <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-500 dark:text-gray-400">Our trusted partner</h1>
                <div className="h-1 w-20 bg-indigo-500 rounded"></div>
                <div className="w-full m-2">
                {partners.map(p => 
                    <PartnerCard 
                    id={p.id}
                    key={p.name}
                    _name={p.name}
                    _image={p.image}
                    _url={p.url}
                    _description={p.description}
                    />
                )

                }  
                </div>
            </div>

            <p className="lg:w-1/2 w-full leading-relaxed text-gray-500 dark:text-gray-400">
            If by visiting this website, you were expecting a fully functional online e-commerce platform that gathers <b>good quality products at reasonable prices</b> , search no more. 
            Our partner <a href={main_url} target="_blank"  className="text-sky-500 font-bold"> AliExpress </a> has you covered!
            AliExpress is a Chinese platform that deals with online sales of products from Chinese companies.  
            It has a <b> vast network of suppliers</b> offering a wide range of products at <b> competitive prices</b>. 
            AliExpress sells exclusively outside China. The platform is <b>booming in Russia, Brazil, the United States, and the European Union (Spain and Italy)</b> . <br />
            <a className="btn bg-gradient-to-r from-orange-500 to-purple-400 text-white w-full mt-16" href={main_url} target="_blank" >Continue to AliExpress Website</a>
            </p>
           
            </div>
        </div>
        </section>        
        </>
    )
}